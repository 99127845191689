import File from "./File"
import Product from "./Product"
import Trad from "./Trad"
import CollectionInterface from './interfaces/CollectionInterface';
import FormInterface from "./interfaces/FormInterface";
import FileInterface from './interfaces/FileInterface';


class Collection {
    readonly id : number
    visual ? : File
    name : Trad
    accessibleFrom ? : number
    products ? : Array<Product>

    constructor(collection ? : CollectionInterface) {
        this.id = collection?.id ?? 0
        if(collection?.visual) this.visual = new File(collection.visual)
        this.name = collection?.name ? new Trad(collection.name) : new Trad({id:0, fr : "", en : ""})
        this.accessibleFrom = collection?.accessibleFrom
        let products : Array<Product> = []
        collection?.products?.forEach(product => {
            products.push(new Product(product))
        })
        this.products = products
    }

    public set nameFR(name_fr : string) {
        this.name.fr = name_fr
    }

    public get nameFR() {
        return this.name.fr
    }

    public set nameEN(name_en : string) {
        this.name.en = name_en
    }

    public get nameEN() {
        return this.name.en ?? ""
    }

    public set visualFile(file : FileInterface) {
        this.visual = new File(file)
    }

    public get visualFile() : any {
        return this.visual
    }

    public get BODY() : Object {
        return {
            name : {
                fr : this.nameFR,
                en : this.nameEN
            },
            visual_id : this.visual?.id,
            accessibleFrom : this.accessibleFrom
        }
    }

    public static get FORM_ADD() : Array<FormInterface> {
        return [{
            wording : "Nom de la collection FR :",
            value : "nameFR",
            type : "text"
        }, {
            wording : "Nom de la collection EN :",
            value : "nameEN",
            type : "text"
        }, {
            wording : "Accessible à partir de :",
            value : "accessibleFrom",
            type : "number"
        }, {
            wording : "Visuel :",
            value : "visualFile",
            type : "file"
        }]
    }

    public static get FORM_EDIT() : Array<FormInterface> {
        return [{
            wording : "Nom de la collection FR :",
            value : "nameFR",
            type : "text"
        }, {
            wording : "Nom de la collection EN :",
            value : "nameEN",
            type : "text"
        }, {
            wording : "Accessible à partir de :",
            value : "accessibleFrom",
            type : "number"
        }, {
            wording : "Visuel :",
            value : "visualFile",
            type : "file"
        }]
    }

    public static ERROR(message : string) : string {
        if(message === "COLLECTION_SAME_ACCESS_POINT") message = "Le nombre de points d'accessibilité concernent déjà une autre collection, veuillez les modifier. "
        if(message === "COLLECTION_CANT_BE_DELETE") message = "La collection ne peut pas être supprimer tant qu'il y a encore des produits dans celle ci"
        return message
    }

}

export default Collection
