import * as React from 'react';
import DataContext from '../../hooks/contexts/DataContext';

export interface AnonymousPageProps {
    history : any
}
 
const AnonymousPage: React.FunctionComponent<AnonymousPageProps> = ({history, children}) => {

    const {data} = React.useContext(DataContext)
    React.useEffect(() => {
        if(data.token) {
            history.push("/")
        }
    },[data.token])

    return ( 
        <div className="page-container bg-light">
            {children}
        </div>
     );
}
 
export default AnonymousPage;
