import * as React from 'react';
import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../../../components/Page/Page';
import UploadFile from '../../../components/UploadFile/UploadFile';
import "./LaunchVisuals.css";
import LaunchVisual from '../../../objects/LaunchVisual';
import useAPI from '../../../hooks/useAPI';
import { toast } from 'react-toastify';
import Field from '../../../components/Field/Field';
import dateToString from '../../../utils/dateToString';

interface LaunchVisualsProps extends RouteComponentProps {
    
}
 
const LaunchVisuals: React.FunctionComponent<LaunchVisualsProps> = ({history}) => {

    const [newLaunchVisual, setNewLaunchVisual] = useState({
        beginDate : "",
        endDate : "",
        file_id : 0
    })

    const [launchVisual, setLaunchVisual] = useState<LaunchVisual>();

    const [deleteCurrentLaunchVisual, responseDeleteCurrentLaunchVisual] = useAPI("LAUNCH_VISUAL", "PUT")
    useEffect(() => {
        const {onSuccess , onFailure} = responseDeleteCurrentLaunchVisual
        if(onSuccess) {
            setLaunchVisual(undefined)
        }
        if(onFailure) {
            toast.error("Une erreur est survenue")
        }
    }, [responseDeleteCurrentLaunchVisual]);

    const [postLaunchVisual, responsePostLaunchVisual] = useAPI("LAUNCH_VISUAL", "POST")
    useEffect(() => {

        const {onSuccess, data, onFailure} = responsePostLaunchVisual
        if(onSuccess) {
            setLaunchVisual(data)
        }
        if(onFailure) {
            toast.error("Une erreur est survenue")
        }
    }, [responsePostLaunchVisual]);


    const [getCurrentLaunchVisual, responseGetCurrentLaunchVisual] = useAPI("LAUNCH_VISUAL", "GET")
    useEffect(() => {

        const {onSuccess, data} = responseGetCurrentLaunchVisual
        if(onSuccess) {
            if(data !== "NO") setLaunchVisual(new LaunchVisual(data))
            setNewLaunchVisual({...newLaunchVisual, file_id : data?.file?.id })
        }
    }, [responseGetCurrentLaunchVisual]);


    useEffect(() => {
        getCurrentLaunchVisual()
    }, []);
    return ( 
        <Page name="Visuel de lancement" history={history}>
            <div className="launch-visual-container">
                {!(launchVisual?.isCurrent) && <UploadFile onUploadedFile={(file) => setNewLaunchVisual({...newLaunchVisual, file_id : file?.id?? 0})} fileUploaded={launchVisual?.file} wording="Visuel de lancement" width={300} height={500} canBeDeleted={false}/>}
                {(launchVisual?.file?.format !== "mp4" && launchVisual?.isCurrent) ?
                <img className="launch-visual" src={launchVisual?.file?.url_img} ></img>
                 : (launchVisual?.isCurrent) && <video className="launch-visual" src={launchVisual?.file?.url_img}controls>
                </video>}
                <div>
                    {launchVisual?.isCurrent ?<label>Du {dateToString(new Date(launchVisual?.beginDate ?? ""))} au {dateToString(new Date(launchVisual?.beginDate ?? ""))}</label> :
                    <>
                        <Field wording="Date de début" type="date" value={newLaunchVisual?.beginDate ?? ""} onChange={(text) => setNewLaunchVisual({...newLaunchVisual, beginDate : text})} />
                        <Field wording="Date de fin" type="date" value={newLaunchVisual?.endDate ?? ""} onChange={(text) => setNewLaunchVisual({...newLaunchVisual, endDate : text})} />
                        <div onClick={() => postLaunchVisual({body : newLaunchVisual})} className="btn bg-black">Valider</div>
                    </>}
                    {launchVisual?.isCurrent && <div onClick={() => deleteCurrentLaunchVisual()} className="btn bg-danger">Supprimer</div>}
                </div>
            </div>
        </Page>
     );
}
 
export default LaunchVisuals;
