import TradInterface from './interfaces/TradInterface';
class Trad {
    readonly id : number
    fr : string
    en ? : string
    ar ? : string

    constructor(trad : TradInterface) {
        this.id = trad.id
        this.fr = trad.fr
        this.en = trad.en
        this.ar = trad.ar
    }
}

export default Trad
