import * as React from 'react';
import reducerPath from '../../objects/types/reducerPath';


interface dataRed {
    newData : any
    reducerPath : reducerPath
}
interface DataContextInterface {
    data : any
    dispatch : (dataReducer : dataRed) => any
}

const DataContext = React.createContext<DataContextInterface>({
    data : "",
    dispatch : (dataReducer) => {}
})

export default DataContext
