import * as React from "react";
import { useState } from "react";
import Page from "../../../components/Page/Page";
import Collection from "../../../objects/Collection";
import useAPI from "../../../hooks/useAPI";
import { useEffect } from "react";
import "./Collections.css";
import ListCards from "../../../components/ListCards/ListCards";
import Form from "../../../components/Form/Form";
import PageContext from "../../../hooks/contexts/PageContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

export interface CollectionsProps {
  history: any;
}

export interface CardCollectionProps {
  source: string;
  title: string;
  accessibleFrom: number;
  onClickEdit: () => any;
  onClickCollection: () => any;
}

export interface ModalCollectionProps {
  show: boolean;
  collection?: Collection | "new";
  onClose: () => any;
}

const Collections: React.FunctionComponent<CollectionsProps> = ({
  history,
}) => {
  const [collections, setCollections] = useState<Array<Collection>>([]);

  const [selectedCollection, setSelectedCollection] = useState<
    Collection | "new"
  >();

  const [getCollections, responseGetCollections] = useAPI("COLLECTIONS", "GET");
  useEffect(() => {
    const { onSuccess, data } = responseGetCollections;

    if (onSuccess) setCollections(data);
  }, [responseGetCollections]);

  React.useEffect(() => {
    getCollections({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFailure = (item: Collection) => {
    setSelectedCollection(item ?? "new");
  };

  const CardCollection: React.FunctionComponent<CardCollectionProps> = ({
    source,
    title,
    accessibleFrom,
    onClickEdit,
    onClickCollection,
  }) => {
    return (
      <div
        onClick={onClickCollection}
        style={{
          width: "100%",
          height: "100%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
        }}
        className="bg-white"
      >
        <img
          alt=""
          src={source}
          style={{ width: "60%", height: "60%", marginTop: "10px" }}
        />
        <label style={{ marginTop: "30px" }}>
          {title} ({accessibleFrom} pts)
        </label>
        <div
          onClick={(e) => {
            e.stopPropagation();
            onClickEdit();
          }}
          style={{
            position: "absolute",
            width: 15,
            height: 15,
            right: 2,
            bottom: 2,
            backgroundColor: "rgb(60,60,60)",
            padding: 10,
          }}
          className="btn-edit"
        >
          <FontAwesomeIcon
            color={"#ffffff"}
            icon={faPencilAlt}
            className="navbar-menu-burger"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
    );
  };

  return (
    <Page history={history} name={"Collections"}>
      <div className="container-collections">
        <PageContext.Provider
          value={{ onFailure, list: collections, setList: setCollections }}
        >
          <ListCards
            data={collections}
            card={(item) => (
              <CardCollection
                onClickCollection={() => history.push(`/collection/${item.id}`)}
                onClickEdit={() => setSelectedCollection(item)}
                source={item.visual?.url_img}
                title={item.name.fr}
                accessibleFrom={item.accessibleFrom}
              />
            )}
            onClickBtnAdd={() => setSelectedCollection("new")}
          />
          <ModalCollection
            show={selectedCollection ? true : false}
            collection={selectedCollection}
            onClose={() => setSelectedCollection(undefined)}
          />
        </PageContext.Provider>
      </div>
    </Page>
  );
};

const ModalCollection: React.FunctionComponent<ModalCollectionProps> = ({
  show,
  collection,
  onClose,
}) => {
  return (
    <div className={show ? "container-modal" : "container-modal-hide"}>
      {collection === "new" ? (
        <div className="modal-collection">
          <label className="title">Nouvelle Collection</label>
          <Form
            onClose={onClose}
            form={Collection.FORM_ADD}
            object={Collection}
            type="POST"
            request="COLLECTIONS"
          />
        </div>
      ) : (
        <div className="modal-collection">
          <label className="title">Collection</label>
          <Form
            item={collection ?? {}}
            onClose={onClose}
            form={Collection.FORM_EDIT}
            object={Collection}
            type="PUT"
            request="COLLECTIONS"
          />
        </div>
      )}
    </div>
  );
};
export default Collections;
