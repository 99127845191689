import * as React from 'react';
import ColumnInterface from '../../objects/interfaces/ColumnInterface';
import "./Table.css"
import FilterInterface from '../../objects/interfaces/FilterInterface';
import Field from '../Field/Field';
import Select from '../Select/Select';
import { CSVLink, CSVDownload } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFile } from '@fortawesome/free-solid-svg-icons';
export interface TableProps {
    columns : Array<ColumnInterface>
    data : Array<any>
    onClickItem ? : (item : any) => any
    customButtons ? : any
    hasBtnAction ? : boolean
    filters ? : Array<FilterInterface>
    isExportable ? : boolean
    isClickable ? : boolean
    isMultipleSelect ? : boolean
    multipleSelected ? : Array<number>
    onSelectedMultiple ? : (id : number) => void
    customCsv ? : Array<ColumnInterface>
}
 
const Table: React.FunctionComponent<TableProps> = ({columns=[], data=[], onClickItem=()=>{}, customButtons, hasBtnAction=false, filters, isExportable, isMultipleSelect, multipleSelected, onSelectedMultiple, isClickable = true, customCsv}) => {

    const [filterParam, setFilterParam] = React.useState<any>({})

    const [orderBy, setOrderBy] = React.useState<string>("")
    const [order, setOrder] = React.useState<"DEFAULT" | "ASC" | "DESC">()

    React.useEffect(() => {
        if(columns.length > 0) setOrderBy(columns[0].value)
    }, [columns]);

    const onClickCol = (col : string) => {
        if(orderBy === col) {
            let newOrder : "DEFAULT" | "ASC" | "DESC" = (order === "ASC") ? "DESC" : (order === "DESC") ? "DEFAULT" : "ASC"
            setOrder(newOrder)
        } else {
            setOrderBy(col)
            setOrder("ASC")
        }
    }

    let dataFilter = [...data]
    for (var key in filterParam) {
        console.log(filterParam)
        if (filterParam.hasOwnProperty(key)) {
            if (filterParam[key].type === "text") dataFilter = dataFilter.filter(i => i[key].toLowerCase().includes(filterParam[key].value.toLowerCase()))
            if (filterParam[key].type === "number" && filterParam[key].value.max) dataFilter = dataFilter.filter(i => ((i[key] <= parseInt(filterParam[key].value.max))))
            if (filterParam[key].type === "number" && filterParam[key].value.min) dataFilter = dataFilter.filter(i => ((i[key] >= parseInt(filterParam[key].value.min))))
            if (filterParam[key].type === "date" && filterParam[key].value.max) dataFilter = dataFilter.filter(i => ((i[key] <= new Date(filterParam[key].value.max))))
            if (filterParam[key].type === "date" && filterParam[key].value.min) dataFilter = dataFilter.filter(i => i[key] >= new Date(filterParam[key].value.min))
            if (filterParam[key].type === "select" && filterParam[key].value) dataFilter = dataFilter.filter(i => ((i[key]) === filterParam[key].value))

        }
    }

    if(order === "ASC") {
        dataFilter.sort(function compare(a, b) {
            if (a[orderBy] < b[orderBy])
               return -1;
            if (a[orderBy] > b[orderBy] )
               return 1;
            return 0;
          });
    } else if(order === "DESC") {
        dataFilter.sort(function compare(a, b) {
            if (a[orderBy] > b[orderBy])
               return -1;
            if (a[orderBy] < b[orderBy] )
               return 1;
            return 0;
          });
    }


    let csvData : Array<Array<string>> = [];


    React.useMemo(() => {
        let cols = customCsv ?? columns
        let titleLine : Array<string>= cols.reduce((acc : Array<string>, curr) => {
            acc.push(curr.name)
            return acc
        }, [])
        csvData.push(titleLine)
        let data : Array<Array<string>> = dataFilter?.reduce((acc : Array<Array<string>>, curr) => {
            let line : Array<string> = []
            cols.forEach(col => {
                line.push(curr[col.value])
            })
            acc.push(line)
            return acc
        }, [])
        data.forEach(i => csvData.push(i))
    }, [dataFilter])

    return ( 
        <div className="table-component">
            {isExportable && <CSVLink className="btn bg-info" data={csvData} >Exporter</CSVLink>}
                {filters && <div className="filters-container bg-white">
                {filters.map(filter =>
                    <div key={filter.value} className="filter-container">
                        {filter.type === "text" && <Field wording={filter.wording} value={filterParam[filter.value]?.value ?? ""} onChange={(text) => setFilterParam({ ...filterParam, [filter.value]: { value: text, type: "text" } })} />}
                        {filter.type === "number" && <>
                            <Field wording={`${filter.wording} Min.`} value={filterParam[filter.value]?.value.min ?? ""} onChange={(text) => setFilterParam({ ...filterParam, [filter.value]: { value: { max: filterParam[filter.value]?.value.max, min: text }, type: "number" } })} />
                            <Field wording={`${filter.wording} Max.`} value={filterParam[filter.value]?.value.max ?? ""} onChange={(text) => setFilterParam({ ...filterParam, [filter.value]: { value: { min: filterParam[filter.value]?.value.min, max: text }, type: "number" } })} />
                        </>}
                        {filter.type === "date" && <>
                            <Field type="date" wording={`${filter.wording} Min.`} value={filterParam[filter.value]?.value.min ?? ""} onChange={(text) => setFilterParam({ ...filterParam, [filter.value]: { value: { max: filterParam[filter.value]?.value.max, min: text }, type: "date" } })} />
                            <Field type="date"  wording={`${filter.wording} Max.`} value={filterParam[filter.value]?.value.max ?? ""} onChange={(text) => setFilterParam({ ...filterParam, [filter.value]: { value: { min: filterParam[filter.value]?.value.min, max: text }, type: "date" } })} />
                        </>}
                        {filter.type === "select" && <Select options={filter.options} config={filter.config} wording={filter.wording} value={filterParam[filter.value]?.value ?? ""} onSelect={(text) => setFilterParam({ ...filterParam, [filter.value]: { value: text, type: "select" } })} />}


                    </div>
                )}
                <div className="filter-container">
                    <div className="btn bg-dark" onClick={() => setFilterParam({})}>
                        Supprimer la recherche
                    </div>
                </div>
            </div>}
            <table className="table">
                <thead className="thead">
                    <tr className="bg-black">
                        {(isMultipleSelect) && <th></th>}
                    {columns.map(col => 
                        <th onClick={() => onClickCol(col.value)} key={`col-${col.value}`}>{col.name}</th>
                    )}
                    {hasBtnAction && <th key={`col-action`}>Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {dataFilter.map(item =>
                        <tr key={`row-${item.id}`} className="row">
                            {(isMultipleSelect) && <td>
                                <input type="checkbox" checked={multipleSelected?.includes(item.id)} onChange={() => onSelectedMultiple && onSelectedMultiple(item.id)}/>
                            </td>}
                            {columns.map(col => 
                                <>
                                {col.type === "file" ? <td key={`${col.value}-${item.id}`}><FontAwesomeIcon icon={faFile} className="navbar-menu-burger navbar-title" onClick={() => window.open(item[col.value], "_blank")} /> </td> :
                                <td className={(!isClickable) ? "no-click" : ""}  onClick={() => onClickItem(item)}  key={`${col.value}-${item.id}`}>{item[col.value]} </td>}
                                </>
                            )}
                            {hasBtnAction &&<td key={`action-${item.id}`}>
                                {customButtons(item)}
                            </td>}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
     );
}
 
export default Table;
