import * as React from 'react';
import Page from '../../../components/Page/Page';
import PageContext from '../../../hooks/contexts/PageContext';
import useAPI from '../../../hooks/useAPI';
import "./NfcTags.css"
import Invoice from '../../../objects/Invoice';
import Table from '../../../components/Table/Table';
import { RouteComponentProps } from 'react-router-dom';
import { useEffect } from 'react';
import NfcTag from '../../../objects/NfcTag';

export interface NfcTagsProps extends RouteComponentProps {
}
 
const NfcTags: React.FunctionComponent<NfcTagsProps> = ({history}) => {
    const [nfcTags, setNfcTags] = React.useState<Array<NfcTag>>([])
    const [getNfcTags, responseGetNfcTags] = useAPI("NFC_TAGS", "GET")
    useEffect(() => {
        const {onSuccess, data} = responseGetNfcTags
        if(onSuccess) {
            setNfcTags(data)
        }
    }, [responseGetNfcTags])
    useEffect(() => {
        getNfcTags()
    }, [])

    const onFailure = () => {

    }

    return ( 
        <Page history={history} name="Tag NFC">
            <div className="container-invoices">
            <PageContext.Provider value={{list : nfcTags, setList : setNfcTags, onFailure}}>
                <Table isClickable={false} isExportable={true} filters={NfcTag.FILTERS_FOR_NFC_TAGS} onClickItem={() => {}} columns={NfcTag.COLUMNS} data={nfcTags}/>
            </PageContext.Provider>
            </div>
        </Page>
     );
}
 
export default NfcTags;
