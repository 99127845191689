import ProductInterface from "./interfaces/ProductInterface";
import Trad from "./Trad";
import Collection from "./Collection";
import File from "./File";
import Category from "./Category";
import FormInterface from "./interfaces/FormInterface";
import FileInterface from "./interfaces/FileInterface";
import Stock from "./Stock";
import FilterInterface from "./interfaces/FilterInterface";
import target from "./types/target";

class Product {
  readonly id?: number | null;
  reference: string;
  name: Trad;
  description: Trad;
  collection?: Collection;
  category?: Category;
  stock: Stock;
  point: number;
  principal_visual?: File;
  second_visual?: File;
  third_visual?: File;
  fourth_visual?: File;
  fifth_visual?: File;
  sixth_visual?: File;
  price: number;
  color?: string;
  isCall?: boolean;
  materialAndMaintenance?: Trad;
  targetPerson?: Array<target>;
  videoUrl?: string;

  constructor(product: ProductInterface | undefined) {
    console.log(product?.id);
    this.id = product?.id ?? 0;
    this.reference = product?.reference ?? "";
    this.name = product?.name
      ? new Trad(product.name)
      : new Trad({ fr: "", en: "", id: 0 });
    this.description = product?.description
      ? new Trad(product.description)
      : new Trad({ fr: "", en: "", id: 0 });
    this.materialAndMaintenance = product?.materialAndMaintenance
      ? new Trad(product.materialAndMaintenance)
      : new Trad({ fr: "", en: "", id: 0 });
    if (product?.collection)
      this.collection = new Collection(product.collection);
    if (product?.category) this.category = new Category(product.category);
    this.stock = product?.stock ? new Stock(product.stock) : new Stock();
    this.point = product?.point ?? 0;
    if (product?.principal_visual)
      this.principal_visual = new File(product?.principal_visual);
    if (product?.second_visual)
      this.second_visual = new File(product?.second_visual);
    if (product?.third_visual)
      this.third_visual = new File(product?.third_visual);
    if (product?.fourth_visual)
      this.fourth_visual = new File(product?.fourth_visual);
    if (product?.fifth_visual)
      this.fifth_visual = new File(product?.fifth_visual);
    if (product?.sixth_visual)
      this.fifth_visual = new File(product?.sixth_visual);
    this.price = product?.price ?? 0;
    this.color = product?.color;
    this.isCall = product?.isCall;

    this.targetPerson = product?.targetPerson ?? ["H", "F", "E"];
    this.videoUrl = product?.videoUrl;
  }

  public get nameFR() {
    return this.name.fr;
  }

  public set nameFR(name: string) {
    this.name.fr = name;
  }

  public get nameEN() {
    return this.name.en ?? "";
  }

  public set nameEN(name: string) {
    this.name.en = name;
  }

  public get descriptionFR() {
    return this.description.fr;
  }

  public set descriptionFR(description: string) {
    this.description.fr = description;
  }

  public get descriptionEN() {
    return this.description.en ?? "";
  }

  public set descriptionEN(description: string) {
    this.description.en = description;
  }

  public get materialAndMaintenanceFR() {
    return this.materialAndMaintenance?.fr ?? "";
  }

  public set materialAndMaintenanceFR(description: string) {
    if (this.materialAndMaintenance)
      this.materialAndMaintenance.fr = description;
  }

  public get materialAndMaintenanceEN() {
    return this.materialAndMaintenance?.en ?? "";
  }

  public set materialAndMaintenanceEN(description: string) {
    if (this.materialAndMaintenance)
      this.materialAndMaintenance.en = description;
  }

  public set principalVisualFile(file: FileInterface) {
    this.principal_visual = new File(file);
  }

  public get principalVisualFile(): any {
    return this.principal_visual ?? null;
  }

  public set secondVisualFile(file: FileInterface) {
    this.second_visual = new File(file);
  }

  public get secondVisualFile(): any {
    return this.second_visual ?? null;
  }

  public set thirdVisualFile(file: FileInterface) {
    this.third_visual = new File(file);
  }

  public get thirdVisualFile(): any {
    return this.third_visual ?? null;
  }

  public set fourthVisualFile(file: FileInterface) {
    this.fourth_visual = new File(file);
  }

  public get fourthVisualFile(): any {
    return this.fourth_visual ?? null;
  }

  public set fifthVisualFile(file: FileInterface) {
    this.fifth_visual = new File(file);
  }

  public get fifthVisualFile(): any {
    return this.fifth_visual ?? null;
  }

  public set sixthVisualFile(file: FileInterface) {
    this.sixth_visual = new File(file);
  }

  public get sixthVisualFile(): any {
    return this.sixth_visual ?? null;
  }

  public set xs(xs: number) {
    this.stock.XS = xs;
  }

  public get xs(): number {
    return this.stock.XS;
  }

  public set s(s: number) {
    this.stock.S = s;
  }

  public get s(): number {
    return this.stock.S;
  }

  public set m(m: number) {
    this.stock.M = m;
  }

  public get m(): number {
    return this.stock.M;
  }

  public set l(l: number) {
    this.stock.L = l;
  }

  public get l(): number {
    return this.stock.L;
  }

  public set xl(xl: number) {
    this.stock.XL = xl;
  }

  public get xl(): number {
    return this.stock.XL;
  }

  public isSuperiorToMinPrice(minPrice: number): boolean {
    return minPrice <= this.price;
  }

  public isInferiorToMaxPrice(maxPrice: number): boolean {
    return maxPrice >= this.price;
  }

  public get BODY(): Object {
    return {
      name: {
        fr: this.nameFR,
        en: this.nameEN,
      },
      description: {
        fr: this.descriptionFR,
        en: this.descriptionEN,
      },
      materialAndMaintenance: {
        fr: this.materialAndMaintenanceFR,
        en: this.materialAndMaintenanceEN,
      },
      category_id: this.category?.id,
      collection_id: this.collection?.id,
      principal_visual_id: this.principal_visual?.id,
      second_visual_id: this.second_visual?.id,
      third_visual_id: this.third_visual?.id,
      fourth_visual_id: this.fourth_visual?.id,
      fifth_visual_id: this.fifth_visual?.id,
      sixth_visual_id: this.sixth_visual?.id,
      reference: this.reference,

      stock: {
        xs: this.xs,
        s: this.s,
        m: this.m,
        l: this.l,
        xl: this.xl,
      },
      color: this.color,
      point: this.point,
      price: this.price,
      targetPerson: this.targetPerson,
      videoUrl: this.videoUrl,
    };
  }

  public static get FORM_ADD(): Array<FormInterface> {
    return [
      {
        wording: "Nom FR",
        value: "nameFR",
        type: "text",
        position: "product-grid-1",
      },
      {
        wording: "Nom EN",
        value: "nameEN",
        type: "text",
        position: "product-grid-1",
      },
      {
        wording: "Reference",
        value: "reference",
        type: "text",
        position: "product-grid-1",
      },
      {
        wording: "Description FR",
        value: "descriptionFR",
        type: "textarea",
        position: "product-grid-5",
      },
      {
        wording: "Description EN",
        value: "descriptionEN",
        type: "textarea",
        position: "product-grid-5",
      },
      {
        wording: "Matière et Entretien FR",
        value: "materialAndMaintenanceFR",
        type: "textarea",
        position: "product-grid-6",
      },
      {
        wording: "Matière et Entretien EN",
        value: "materialAndMaintenanceEN",
        type: "textarea",
        position: "product-grid-6",
      },
      {
        wording: "Catégorie",
        value: "category",
        type: "select",
        position: "product-grid-1",
        config: {
          api: {
            method: "GET",
            name: "CATEGORIES",
            modelOption: {
              name: "nameFR",
              value: "id",
            },
          },
        },
      },
      {
        wording: "Collection",
        value: "collection",
        type: "select",
        position: "product-grid-3",
        config: {
          api: {
            method: "GET",
            name: "COLLECTIONS",
            modelOption: {
              name: "nameFR",
              value: "id",
            },
          },
        },
      },
      {
        wording: "XS",
        value: "xs",
        type: "number",
        position: "product-grid-7",
      },
      {
        wording: "S",
        value: "s",
        type: "number",
        position: "product-grid-7",
      },
      {
        wording: "M",
        value: "m",
        type: "number",
        position: "product-grid-7",
      },
      {
        wording: "L",
        value: "l",
        type: "number",
        position: "product-grid-7",
      },
      {
        wording: "XL",
        value: "xl",
        type: "number",
        position: "product-grid-7",
      },
      {
        wording: "Couleur",
        value: "color",
        type: "text",
        position: "product-grid-1",
      },
      {
        wording: "Prix Standard",
        value: "price",
        type: "number",
        position: "product-grid-4",
      },
      {
        wording: "Point",
        value: "point",
        type: "number",
        position: "product-grid-4",
      },
      {
        wording: "Visuel principal",
        value: "principalVisualFile",
        type: "file",
        position: "product-grid-2",
      },
      {
        wording: "Deuxième visuel",
        value: "secondVisualFile",
        type: "file",
        position: "product-grid-2",
      },
      {
        wording: "Troisième visuel",
        value: "thirdVisualFile",
        type: "file",
        position: "product-grid-2",
      },
      {
        wording: "Quatrième visuel",
        value: "fourthVisualFile",
        type: "file",
        position: "product-grid-2",
      },
      {
        wording: "Cinquième visuel",
        value: "fifthVisualFile",
        type: "file",
        position: "product-grid-2",
      },
      {
        wording: "Sixième visuel",
        value: "sixthVisualFile",
        type: "file",
        position: "product-grid-2",
      },
      {
        wording: "",
        value: "targetPerson",
        type: "select_target",
        position: "product-grid-9",
      },
      {
        wording: "URL de la video",
        value: "videoUrl",
        type: "text",
        position: "product-grid-10",
      },
    ];
  }

  public static get FILTERS_FOR_CATEGORY(): Array<FilterInterface> {
    return [
      {
        wording: "Nom",
        value: "nameFR",
        type: "text",
      },
      {
        wording: "Référence",
        value: "reference",
        type: "text",
      },
      {
        wording: "Prix",
        value: "price",
        type: "number",
      },
      {
        wording: "Point",
        value: "point",
        type: "number",
      },
      {
        wording: "Collection",
        value: "collection",
        type: "select",
        config: {
          api: {
            method: "GET",
            name: "COLLECTIONS",
            modelOption: {
              name: "nameFR",
              value: "id",
            },
          },
        },
      },
    ];
  }

  public static get FILTERS_FOR_COLLECTION(): Array<FilterInterface> {
    return [
      {
        wording: "Nom",
        value: "nameFR",
        type: "text",
      },
      {
        wording: "Référence",
        value: "reference",
        type: "text",
      },
      {
        wording: "Prix",
        value: "price",
        type: "number",
      },
      {
        wording: "Point",
        value: "point",
        type: "number",
      },
      {
        wording: "Catégorie",
        value: "category",
        type: "select",
        config: {
          api: {
            method: "GET",
            name: "CATEGORIES",
            modelOption: {
              name: "nameFR",
              value: "id",
            },
          },
        },
      },
    ];
  }
}

export default Product;
