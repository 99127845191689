import * as React from 'react';
import { useState } from 'react';
import FilterInterface from '../../objects/interfaces/FilterInterface';
import Field from '../Field/Field';
import Select from '../Select/Select';
import "./ListItem.css"
export interface ListItemsProps {
    data: Array<any>
    filters?: Array<FilterInterface>
    item: (item: any) => JSX.Element
    onClickAddBtn?: () => any
}

const ListItems: React.FunctionComponent<ListItemsProps> = ({ data, item, filters, onClickAddBtn }) => {

    const [filterParam, setFilterParam] = useState<any>({})

    let dataFilter = [...data]
    for (var key in filterParam) {
        console.log(filterParam)
        if (filterParam.hasOwnProperty(key)) {
            if (filterParam[key].type === "text") dataFilter = dataFilter.filter(i => i[key].toLowerCase().includes(filterParam[key].value.toLowerCase()))
            if (filterParam[key].type === "number" && filterParam[key].value.max) dataFilter = dataFilter.filter(i => ((i[key] <= parseInt(filterParam[key].value.max))))
            if (filterParam[key].type === "number" && filterParam[key].value.min) dataFilter = dataFilter.filter(i => ((i[key] >= parseInt(filterParam[key].value.min))))
            if (filterParam[key].type === "select" && filterParam[key].value) dataFilter = dataFilter.filter(i => ((i[key].id) === filterParam[key].value.id))

        }
    }


    return (
        <div className="list-items">
            <div className="btn bg-dark" onClick={onClickAddBtn}>Ajouter</div>
            {filters && <div className="filters-container bg-white">
                {filters.map(filter =>
                    <div key={filter.value} className="filter-container">
                        {filter.type === "text" && <Field wording={filter.wording} value={filterParam[filter.value]?.value ?? ""} onChange={(text) => setFilterParam({ ...filterParam, [filter.value]: { value: text, type: "text" } })} />}
                        {filter.type === "number" && <>
                            <Field wording={`${filter.wording} Min.`} value={filterParam[filter.value]?.value.min ?? ""} onChange={(text) => setFilterParam({ ...filterParam, [filter.value]: { value: { max: filterParam[filter.value]?.value.max, min: text }, type: "number" } })} />
                            <Field wording={`${filter.wording} Max.`} value={filterParam[filter.value]?.value.max ?? ""} onChange={(text) => setFilterParam({ ...filterParam, [filter.value]: { value: { min: filterParam[filter.value]?.value.min, max: text }, type: "number" } })} />
                        </>}
                        {filter.type === "select" && <Select config={filter.config} wording={filter.wording} value={filterParam[filter.value]?.value ?? ""} onSelect={(text) => setFilterParam({ ...filterParam, [filter.value]: { value: text, type: "select" } })} />}


                    </div>
                )}
                <div className="filter-container">
                    <div className="btn bg-dark" onClick={() => setFilterParam({})}>
                        Supprimer la recherche
                    </div>
                </div>
            </div>}

            {dataFilter.map(i =>
                <div key={i.id} className="item-container">
                    {item(i)}
                </div>
            )}
        </div>
    );
}

export default ListItems;
