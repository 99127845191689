import axios  from '../axios';
import URL_API from '../constants/URL_API';
import method from '../objects/types/method';


export const setRequestAPI = async (method : method, url : string, data : Object | undefined, params : any ) => {
    return await axios({
        method,
        url,
        data,
        params,
    })
}

export const postFile = async (data : FormData) => {
    const config = {
        headers: {'Content-Type': 'multipart/form-data'}
    };
    return await axios.post(URL_API +"/api/files/upload", data, config);
};


export const putFile = async (data : FormData, id: number) => {
    const config = {
        headers: {'Content-Type': 'multipart/form-data'}
    };
    return await axios.post(URL_API +"/api/files/" + id, data, config);
};
