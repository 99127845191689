import Customer from './Customer';
import Product from './Product';
import NfcTagInterface from './interfaces/NfcTagInterface';
import ColumnInterface from './interfaces/ColumnInterface';
import FilterInterface from './interfaces/FilterInterface';

class NfcTag {
    id ? : number
    customer ? : Customer
    product ? : Product
    value ? : string

    get lastName() {
        return this.customer?.lastName ?? "-"
    }

    get firstName() {
        return this.customer?.firstName ?? "-"
    }

    get nbPoints() {
        return this.customer?.nbPoint ??  "-"
    }

    get email() {
        return this.customer?.email ??  "-"
    }

    get username() {
        return this.customer?.username ??  "-"
    }


    get productName() {
        return this.product?.nameFR ??  "-"
    }


    constructor(nfcTag : NfcTagInterface) {
        this.id = nfcTag?.id
        this.customer = new Customer(nfcTag?.customer)
        this.product = new Product(nfcTag?.product)
        this.value = nfcTag?.value
    }

    static get COLUMNS() : Array<ColumnInterface> {
        return [
            {name : "N° Interne", value : "value"},
            {name : "Nom", value : "lastName"},
            {name : "Prénom", value : "firstName"},
            {name : "Nombre de point", value : "nbPoints"},
            {name : "Pseudo", value : "username"},
            {name : "Adresse Mail", value : "email"},
            {name : "Produit", value : "productName"},
        ]
    }

    public static get FILTERS_FOR_NFC_TAGS(): Array<FilterInterface> {
        return [{
            wording: "N° Interne",
            value: "value",
            type: "text"
        }, {
            wording: "Nom",
            value: "lastName",
            type: "text"
        }, {
            wording: "Prénom",
            value: "firstName",
            type: "text"
        }, {
            wording: "Nombre de point",
            value: "nbPoints",
            type: "number"
        }, {
            wording: "Pseudo",
            value: "username",
            type: "text"
        }, {
            wording: "Adresse Mail",
            value: "email",
            type: "text"
        }, {
            wording: "Produit",
            value: "productName",
            type: "text"
        }, ]
    }
}

export default NfcTag
