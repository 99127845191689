import GiftInterface from './interfaces/GiftInterface';
import ColumnInterface from './interfaces/ColumnInterface';
import Customer from './Customer';
import FilterInterface from './interfaces/FilterInterface';

class Gift {
    id ? : number
    customId ? : string
    mail ? : string
    initialAmount ? : number
    remainingPrice ? : number
    toCustomer ? : Customer
    fromCustomer ? : Customer

    get customIdString() {
        return this.customId ?? "-"
    }

    get mailString() {
        return this.mail ?? "-"
    }

    get remainingPriceString() {
        return `${this.remainingPrice ?? 0} €` 
    }

    get initialAmountString() {
        return `${this.initialAmount ?? 0} €` 
    }

    get fromCustomerId() {
        return this.fromCustomer?.customId ?? "-"
    }

    constructor(gift ? : GiftInterface) {
        this.id = gift?.id
        this.customId = gift?.customId
        this.mail = gift?.mail
        this.initialAmount = gift?.initialAmount
        this.remainingPrice = gift?.remainingPrice
        this.fromCustomer = new Customer(gift?.fromCustomer)
        this.toCustomer = new Customer(gift?.toCustomer)
    }

    static get COLUMNS() : Array<ColumnInterface> {
        return [
            {name : "N° de chèque", value : "customIdString"},
            {name : "Mail associé", value : "mailString"},
            {name : "Montant initial", value : "initialAmountString"},
            {name : "Montant restant", value : "remainingPriceString"},
            {name : "Envoyé par", value : "fromCustomerId"},
        ]
    }

    public static get FILTERS_FOR_GIFTS(): Array<FilterInterface> {
        return [{
            wording: "N° de chèque",
            value: "customIdString",
            type: "text"
        }, {
            wording: "Mail associé",
            value: "mailString",
            type: "text"
        }, {
            wording: "Montant initial",
            value: "initialAmountString",
            type: "number"
        }, {
            wording: "Montant restant",
            value: "remainingPriceString",
            type: "number"
        }, {
            wording: "Envoyé par",
            value: "fromCustomerId",
            type: "text"
        }, {
            wording: "Produit",
            value: "productName",
            type: "text"
        }, ]
    }
}

export default Gift
