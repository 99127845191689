import * as React from 'react';


interface PageContextInterface {
    onFailure : any,
    setList : any,
    list : any
}

const PageContext = React.createContext<PageContextInterface>({
    onFailure : (item ? : any) => {},
    setList : (data : Array<any>) => {},
    list : []
})

export default PageContext
