import * as React from "react";
import Page from "../../../components/Page/Page";
import "./Categories.css";
import Category from "../../../objects/Category";
import useAPI from "../../../hooks/useAPI";
import ListCards from "../../../components/ListCards/ListCards";
import Form from "../../../components/Form/Form";
import PageContext from "../../../hooks/contexts/PageContext";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface CardCategoryProps {
  source: string;
  title: string;
  onClickEdit: () => any;
  onClickItem: () => any;
}

export interface CategoriesProps {
  history: any;
}

export interface ModalCategoryProps {
  show: boolean;
  category?: Category | "new";
  onClose: () => any;
}

const Categories: React.FunctionComponent<CategoriesProps> = ({ history }) => {
  const [categories, setCategories] = React.useState<Array<Category>>([]);

  const [selectedCategory, setSelectedCategory] = React.useState<
    Category | "new"
  >();

  const [getCategories, responseGetCategories] = useAPI("CATEGORIES", "GET");
  React.useEffect(() => {
    const { onSuccess, data } = responseGetCategories;

    if (onSuccess) setCategories(data);
  }, [responseGetCategories]);

  React.useEffect(() => {
    getCategories({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CardCategory: React.FunctionComponent<CardCategoryProps> = ({
    source,
    title,
    onClickEdit,
    onClickItem,
  }) => {
    return (
      <div
        onClick={onClickItem}
        style={{
          width: "100%",
          height: "100%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
        }}
        className="bg-white div-category"
      >
        <img
          alt=""
          src={source}
          style={{ width: "60%", height: "60%", marginTop: "10px" }}
        />
        <label style={{ marginTop: "30px" }}>{title}</label>
        <div
          onClick={(e) => {
            e.stopPropagation();
            onClickEdit();
          }}
          style={{
            position: "absolute",
            width: 15,
            height: 15,
            right: 2,
            bottom: 2,
            backgroundColor: "rgb(60,60,60)",
            padding: 10,
          }}
          className="btn-edit"
        >
          <FontAwesomeIcon
            color={"#ffffff"}
            icon={faPencilAlt}
            className="navbar-menu-burger"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
    );
  };

  const onFailure = (item: Category) => {
    setSelectedCategory(item ?? "new");
  };

  return (
    <Page history={history} name="Catégories">
      <div className="container-categories">
        <PageContext.Provider
          value={{ list: categories, setList: setCategories, onFailure }}
        >
          <ListCards
            card={(item) => (
              <CardCategory
                source={item.visual?.url_img}
                title={item.name?.fr}
                onClickItem={() => history.push(`category/${item.id}`)}
                onClickEdit={() => setSelectedCategory(item)}
              />
            )}
            data={categories}
            onClickBtnAdd={() => setSelectedCategory("new")}
          />
          <ModalCategory
            category={selectedCategory}
            show={selectedCategory ? true : false}
            onClose={() => setSelectedCategory(undefined)}
          />
        </PageContext.Provider>
      </div>
    </Page>
  );
};

const ModalCategory: React.FunctionComponent<ModalCategoryProps> = ({
  show,
  category,
  onClose,
}) => {
  return (
    <div className={show ? "container-modal" : "container-modal-hide"}>
      {category === "new" ? (
        <div className="modal-category">
          <label className="title">Nouvelle Catégorie</label>
          <Form
            onClose={onClose}
            form={Category.FORM_ADD}
            object={Category}
            type="POST"
            request="CATEGORIES"
          />
        </div>
      ) : (
        <div className="modal-category">
          <label className="title">Catégorie</label>
          <Form
            item={category ?? {}}
            onClose={onClose}
            form={Category.FORM_EDIT}
            object={Category}
            type="PUT"
            request="CATEGORIES"
          />
        </div>
      )}
    </div>
  );
};
export default Categories;
