import Product from "./Product"
import size from './types/size';
import ProductOrderInterface from './interfaces/ProductOrderInterface';
import ColumnInterface from './interfaces/ColumnInterface';

class ProductOrder {
    readonly id : number
    product ? : Product
    amount : number
    size : size
    _productsTagged ? : number

    constructor(productOrder : ProductOrderInterface) {
        this.id = productOrder.id
        if(productOrder.product) this.product = new Product(productOrder.product)
        this.amount = productOrder.amount
        this.size = productOrder.size
        this._productsTagged = productOrder._productsTagged ?? 0
    }


    public get nameFR() : string {
        return this.product?.name.fr ?? ""
    }

    public get color() : string {
        return this.product?.color ?? ""
    }

    public get price() : string {
        return `${(this.product?.price ?? 0) * this.amount} €` ?? ""
    }

    public static get COLUMNS() : Array<ColumnInterface> {
        return [
            {name : "Nom", value : "nameFR"},
            {name : "Couleur", value : "color"},
            {name : "Taille", value : "size"},
            {name : "Quantité", value : "amount"},
            {name : "Prix total", value : "price"}
        ]
    }
}

export default ProductOrder
