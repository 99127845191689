import * as React from 'react';
import Page from '../../../components/Page/Page';
import "./Users.css"
import PageContext from '../../../hooks/contexts/PageContext';
import User from '../../../objects/User';
import useAPI from '../../../hooks/useAPI';
import { useEffect, useContext } from 'react';
import Table from '../../../components/Table/Table';
import Form from '../../../components/Form/Form';
import useData from '../../../hooks/useData';
import DataContext from '../../../hooks/contexts/DataContext';

export interface UsersProps {
    history : any
}
 
const Users: React.FunctionComponent<UsersProps> = ({history}) => {
    const { data } = useContext(DataContext)
    const [roles] = useData("roles")
    useEffect(() => {

        if(roles && !roles?.includes("ROLE_SUPER_ADMIN") ) history.push("/")
    }, [roles])


    const [users, setUsers] = React.useState<Array<User>>([])

    const [selectedUser, setSelectedUser] = React.useState<User | "new">()


    const [getUsers, responseGetUsers] = useAPI("USERS", "GET")
    React.useEffect(() => {
        const { onSuccess, data} = responseGetUsers
        if(onSuccess) setUsers(data)
        console.log(responseGetUsers)
    }, [responseGetUsers])

    useEffect(() => {
        getUsers()
    },[])

    const onFailure = () => {

    }

    return ( 
        <Page history={history} name="Gestion des utilisateurs">
            {(!roles?.includes("ROLE_SUPER_ADMIN")) ? <></> :
            <div className="container-users">
            <PageContext.Provider value={{list : users, setList : setUsers, onFailure}}>
                <div className="btn bg-dark" onClick={() => setSelectedUser("new")}>Ajouter</div>
                <Table onClickItem={(user) => setSelectedUser(user)} columns={User.COLUMNS} data={users}/>
                <ModalUser show={selectedUser ? true : false} user={selectedUser} onClose={() => setSelectedUser(undefined)}/>
            </PageContext.Provider>
            </div>}
        </Page>
     );
}


interface ModalUserProps {
    show : boolean
    user ? : "new" | User
    onClose : () => any
}

const ModalUser : React.FunctionComponent<ModalUserProps> = ({show, user, onClose}) => {

    return(
        <div className={show ? "modal-user" : "container-modal-hide"}>
               {user === "new" ? <div className="user-container">
                    <Form
                        type="POST"
                        request="USERS"
                        form={User.FORM_ADD}
                        object={User}
                        onClose={onClose}
                    />
                </div> : <div className="user-container">
                    <Form
                        item={user ?? {}}
                        type="PUT"
                        request="USERS"
                        form={User.FORM_EDIT}
                        object={User}
                        getItem={true}
                        onClose={onClose}
                    />
                </div> }
        </div>
    )
}
export default Users;
