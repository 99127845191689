import * as React from 'react';
import "./Checkbox.css"

interface CheckboxProps {
    wording : string
    value : boolean
    onClick : () => any
}
 
const Checkbox: React.FunctionComponent<CheckboxProps> = ({wording , value, onClick}) => {
    return ( 
        <div className={ "checkbox-container"}>
        <label >{wording}</label>
        <div onClick={onClick} className={`checkbox ${value ? "checkbox-active" : "checkbox-inactive"}` } />
    </div>
     );
}
 
export default Checkbox;
