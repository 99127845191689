import * as React from 'react';
import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../../../components/Page/Page';
import Table from '../../../components/Table/Table';
import useAPI from '../../../hooks/useAPI';
import Gift from '../../../objects/Gift';
import "./Gifts.css"
import { toast } from 'react-toastify';

interface GiftsProps extends RouteComponentProps {
    
}
 
const Gifts: React.FunctionComponent<GiftsProps> = ({history}) => {

    const [resendMail, responseResendMail] = useAPI("RESEND_MAIL", "PUT")
    useEffect(() => {
        const { data , onSuccess } = responseResendMail
        if(onSuccess) {
            toast.success("Mail envoyé !")
        }
    }, [responseResendMail]);

    const [gifts, setGifts] = useState<Array<Gift>>([]);

    const [getGifts, responseGetGifts] = useAPI("GIFTS", "GET")
    useEffect(() => {
        const {onSuccess, data} = responseGetGifts
        if(onSuccess) {
            setGifts(data)
        }
    }, [responseGetGifts]);

    useEffect(() => {
        getGifts()
    }, []);

    const customButtons = (gift : Gift) => {
        let btns = []
        if(!gift.toCustomer?.id) {
            btns.push(<div onClick={() => resendMail({id : gift.id})} className="btn bg-info">Renvoyer le mail</div>)
        }
        return btns
    }
    return ( 
        <Page history={history} name="Chèque Cadeau">
            <div className="container-gifts">
            <Table  hasBtnAction={true} isClickable={false} filters={Gift.FILTERS_FOR_GIFTS} onClickItem={() => {}} columns={Gift.COLUMNS} data={gifts} customButtons={customButtons}/>
            </div>
        </Page>
     );
}
 
export default Gifts;
