import * as React from 'react';
import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Field from '../../../components/Field/Field';
import Page from '../../../components/Page/Page';
import TabsBar from '../../../components/TabsBar/TabsBar';
import useAPI from '../../../hooks/useAPI';
import Notification from '../../../objects/Notification';
import "./Notifications.css"
import { toast } from 'react-toastify';
interface NotificationsProps extends RouteComponentProps<any>{
    
}
 
const Notifications: React.FunctionComponent<NotificationsProps> = ({history}) => {

    const [currentPage, setCurrentPage] = useState<"new-notification" | "all-notifications">("new-notification");
    const [notification, setNotification] = useState<Notification>(new Notification())
    const [notifications, setNotifications] = useState<Array<Notification>>([])

    const [getNotifications, responseGetNotifications] = useAPI("NOTIFICATIONS", "GET")
    useEffect(() => {
        const { onSuccess , data } = responseGetNotifications
        if(onSuccess) {
            setNotifications(data)
        }

    }, [responseGetNotifications]);

    const [postSendNotification, responsePostSendNotification] = useAPI("SEND_NOTIFICATION", "POST")
    useEffect(() => {
        const { onSuccess , onFailure, data, error } = responsePostSendNotification
        if(onSuccess) {
            toast.success(`Notification envoyé à ${data} personne(s)`)
        }
        if(onFailure) {
            console.error(error)
            toast.error("Une erreur est survenue")
        }
    }, [responsePostSendNotification]);


    useEffect(() => {
        getNotifications()
    }, []);

    const onChange = (name : string, value : string) => {
        setNotification({...notification, [name] : value})
    }



    return ( 
        <Page history={history} name="Notification">
            <div className="notifications-container">
                <TabsBar selectedTab={currentPage} tabs={[
                        {title : "Nouvelle Notification", onClick : () => setCurrentPage("new-notification"), id : "new-notification"},
                        {title : "Historique des notifications", onClick : () => setCurrentPage("all-notifications"), id : "all-notifications"}
                    ]}/>
               {currentPage === "new-notification" ? <div className='form-notification'>
                    <Field value={notification.title} onChange={(text) => onChange("title", text)} wording='Titre'/>
                    <Field type="textarea" value={notification.content} onChange={(text) =>  onChange("content", text)} wording='Description'/>
                    <Field type="datetime-local" value={notification.programmedAt ?? ""} onChange={(text) =>  onChange("programmedAt", text)} wording='Description'/>
                    <div onClick={() => postSendNotification({body : notification})} className="btn bg-success">Envoyer</div>
                </div> : 
                <>
                    {notifications.map(notification =>
                        <div key={notification.id} className="notification-card">
                            {notification.title} : {notification.content} (reçu par {notification.recipients} personnes)
                        </div>
                    )}
                </>
                }
            </div>
        </Page>
     );
}
 
export default Notifications;
