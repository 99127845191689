const dateToString = (date : Date) : string => {
    date = new Date(date)

    let d = date.getDate()
    let day = (d < 10) ?  `0${d}` : `${d}`

    let m = date.getMonth() + 1
    let month = (m < 10) ?  `0${m}` : `${m}`

    return `${day}/${month}/${date.getFullYear()}`
} 

export default dateToString
