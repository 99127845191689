import * as React from 'react';
import { useState, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Field from '../../../components/Field/Field';
import Form from '../../../components/Form/Form';
import AnonymousPage from '../../../components/Page/AnonymousPage';
import User from '../../../objects/User';
import firebase from '../../../firebase/index';
import "./Login.css"
import useAPI from '../../../hooks/useAPI';
import { useEffect } from 'react';
import useCache from '../../../hooks/useCache';
import DataContext from '../../../hooks/contexts/DataContext';
import { toast } from 'react-toastify';
import logo from '../../../images/logobyciavatti.jpg';

declare global {
    interface Window {
        recaptchaVerifier: any;
        confirmationResult: any
    }
}


export interface LoginProps extends RouteComponentProps {

}

const Login: React.FunctionComponent<LoginProps> = ({ history }) => {

    const [phone, setPhone] = useState<string>("")
    const [code, setCode] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>()

    const [showInputCode, setShowInputCode] = useState<boolean>(false)

    const [token, setToken] = useCache("token")

    const { dispatch } = useContext(DataContext)

    const [getCurrentAdmin, responseGetCurrentAdmin] = useAPI("CURRENT_ADMIN", "GET")
    useEffect(() => {
        const { onSuccess, data } = responseGetCurrentAdmin
            if(onSuccess) {
            dispatch({ reducerPath : "currentAdmin", newData : new User(data)})
            dispatch({ reducerPath : "token", newData: token })
        }
    }, [responseGetCurrentAdmin]);
    const [postPhoneLogin, responsePostPhoneLogin] = useAPI("PHONE_LOGIN", "POST")
    useEffect(() => {
        const { onSuccess, data } = responsePostPhoneLogin
        if (onSuccess) {
            firebase.auth().signOut(); 
            setIsLoading(false)
             setToken(data.token)
             getCurrentAdmin()
        }
    }, [responsePostPhoneLogin])


    const setUpRecaptcha = () => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response: any) => {
                console.log(response)
            }
        });
    }

    const onSignInSubmit = (event: any) => {
        setIsLoading(true)
        event.preventDefault()
        setUpRecaptcha()
        const phoneNumber = phone
        const appVerifier = window.recaptchaVerifier;
        firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult
                setShowInputCode(true)
                setIsLoading(false)
            }).catch((error) => {
                event.preventDefault()
                setUpRecaptcha()
                setIsLoading(false)
                if (error.code === "auth/invalid-phone-number") toast.error("Le numéro est invalide")
                else if (error.code === "auth/too-many-requests") toast.error("Trop de tentative de connexion avec ce numéro (Le numéro est bloqué temporairement)")
                else toast.error("Une erreur est survenue")
                console.error(error)
            });
    }

    const onValidateCode = () => {
        setIsLoading(true)
        var credential = firebase.auth.PhoneAuthProvider.credential(window.confirmationResult.verificationId, code);
        console.log(credential)

        firebase.auth().signInWithCredential(credential).then(() => {
            let currentUser = firebase.auth()?.currentUser
            currentUser?.getIdToken().then(token => {
                postPhoneLogin({ body: { token } })
            })
        }).catch((error: any) => {
            //auth/code-expired
            if(error.code === "auth/code-expired")  toast.error("Le code est expiré")
            if (error.code === "auth/missing-verification-code") toast.error("Le code est manquant")
            else if (error.code === "auth/invalid-verification-code") toast.error("Le code est invalide")
            else toast.error("Une erreur est survenue")
            console.error(error)
        });
    }

    return (
        <>
            <div className="containerLogo"><img src={logo} className="logo" alt="logo" />
            </div>
            <AnonymousPage history={history}>
                <div className="container-form-login">
                    {(!showInputCode) ? <>
                        <Field className="input" value={phone} onChange={setPhone} wording="Téléphone" />
                        <div className={`btn ${isLoading ? "bg-light" : "bg-dark"}`} onClick={onSignInSubmit}>Se Connecter </div>
                    </> : <>
                        <Field className="input" value={code} onChange={setCode} wording="Code" />
                        <div className={`btn ${isLoading ? "bg-light" : "bg-dark"}`} onClick={onValidateCode}>Valider Code </div>
                    </>}

                    <div id="recaptcha-container">
                    </div>
                </div>
            </AnonymousPage>
        </ >
    );
}

export default Login;
