import * as React from 'react';
import "./SelectRoles.css"
import role from '../../objects/types/role';
import { useEffect } from 'react';

export interface SelectRolesProps {
    value : Array<role>
    onChange : (rpme : Array<role>) => any
    error ? : string
    setError ? : () => {}
    className ? : string
    style ? : React.StyleHTMLAttributes<any>
}
 
const SelectRoles: React.FunctionComponent<SelectRolesProps> = ({value=[], onChange}) => {

    const roles : Array<role> = [ "ROLE_ADMIN", "ROLE_SUPER_ADMIN"]
    const rolesString  = {
        "ROLE_TESTER" : "Testeur",
        "ROLE_ADMIN" : "Admin",
        "ROLE_SUPER_ADMIN": "Super Admin",
        "ROLE_OTHER" : "Autre"
    }

    const onClickItem = (role : role) => {
        let newValue = [...value]
        if(value.includes(role)) {
           newValue = newValue.filter(v => v !== role)
        } else {
            newValue.push(role)
        }
        onChange(newValue)
    }

    return ( 
        <div className="select-role-container">
            {roles.map(role =>
                <div onClick={() => onClickItem(role)} className={`label ${value.includes(role) ? "bg-dark" : "bg-light"}`}>{rolesString[role]}</div>
            )}
        </div>
     );
}
 
export default SelectRoles;
